
/**
 * Module dependencies.
 */

import { color, units } from 'src/styles/utils';
import { theme } from 'styled-tools';
import RawHtml from 'src/components/core/raw-html';
import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode,
  className?: string
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  ${theme('typography.styles.p')}

  color: ${color('grey400')};

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 ${units(1)};
  }

  h2 {
    ${theme('typography.styles.h2')}
  }

  h3 {
    ${theme('typography.styles.h3')}
  }

  h4 {
    ${theme('typography.styles.h4')}
  }

  h5 {
    ${theme('typography.styles.h5')}
  }

  h6 {
    ${theme('typography.styles.h6')}
  }

  p {
    margin: 0 0 ${units(5)};
  }

  a {
    color: inherit;
    transition: opacity ${theme('animations.defaultTransition')};

    &:focus,
    &:hover {
      opacity: 0.8;
    }
  }
`;

/**
 * `Richtext` component.
 */

const Richtext = ({ children, className }: Props): ReactElement => (
  <Wrapper className={className}>
    <RawHtml>
      {children}
    </RawHtml>
  </Wrapper>
);

/**
 * Export `Richtext` component.
 */

export default Richtext;
